.more{
    text-decoration: none !important;
    
}
.more:hover{
    color:darkblue !important;
}

.card-text{
    padding-bottom: 2rem !important;
    font-family: 'Times New Roman', Times, serif;
}

.buton{
    background-color:#C92798 !important;
}
.servicebox{
    margin-top: 2rem !important;
}
/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .card-temp{
        height: 33rem !important;
    }
   
    .card-photo{
        height: 15rem !important;
    }
    .card-title{
        font-size: medium !important;
        font-weight: bold !important;
    }
    .card-text{
        font-size: small !important;
        padding-bottom: 1rem !important;
    }
    
   
 
 }

 /* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 570px) {

    .card-temp{
        height: 36rem !important;
    }
    .card-text{
        
        padding-bottom: 0.5rem !important;
    }
}