
.contact{
  
    width: 100%;
    display: block;
    
    
    

}
.gonder:hover{
    background-color: #af832b !important;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 1370px) {

    .iletisimegec{
        font-size: large !important;
        padding-left: -1rem !important;
        font-weight: bold !important;

        
      
    }
    .yardim{
        font-size: medium !important;
        padding-left: -1rem !important;

    }
    

}


/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {

    .iletisimegec{
        font-size: medium !important;
        padding-left: -1rem !important;
        font-weight: bold !important;
        
      
    }
    .yardim{
        font-size: small !important;
        padding-left: -1rem !important;

    }
    .gonder{
        justify-content:center !important;
        margin-left: -2rem !important;
    }
    .contactFormTemp{
        background-color:#DCDCDC !important; 
        background-image: none !important; 
        
    }
    

}